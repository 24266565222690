import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'
import messages from '../../../config/locales/vue.pt-BR.yml'

Vue.use(VueI18n)

const i18n = new VueI18n({
  lang: 'pt-BR',
  fallbackLocale: 'pt-BR',
  messages,
  silentFallbackWarn: true
})

export {
  i18n
}
