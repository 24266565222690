import Vue from 'vue/dist/vue.esm'

const SENTRY_DSN = 'https://d967172f9dfa437ebe749726023b34fd@o466307.ingest.sentry.io/5480468'

async function installSentry() {
  const Sentry = await import( /* webpackChunkName: 'sentry' */ '@sentry/browser');
  const Integrations = await import( /* webpackChunkName: 'sentry' */ '@sentry/integrations');
  const Tracing = await import( /* webpackChunkName: 'sentry' */ '@sentry/tracing');

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true
      }),
      new Tracing.Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0
  });
}

export default installSentry;
